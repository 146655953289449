import React from "react";
import styled from "styled-components";
import { Breakpoint_MD } from "../utils/Constants";

interface StatusCircleProps {
  color?: string;
  label?: string;
  value: string;
  className?: string;
  labelPosition?: "inside" | "outside";
}

export default function StatusCircle({
  color,
  label,
  value,
  className,
  labelPosition,
}: StatusCircleProps) {
  return (
    <Status className={className}>
      <Circle color={color}>
        <div>
          <Value>{value}</Value>
          {labelPosition === "inside" && (
            <>
              <br />
              {label}
            </>
          )}
        </div>
      </Circle>
      {label && labelPosition !== "inside" && <StatusText>{label}</StatusText>}
    </Status>
  );
}

const Circle = styled.div`
  width: 125px;
  height: 125px;
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  border: solid 5px #c8c9c7;
  background-color: ${(props) => props.color};
  margin: auto;
  display: grid;
  align-items: center;
  @media screen and (max-width: ${Breakpoint_MD}) {
    width: 100px;
    height: 100px;
    font-size: 0.85em;
  }
`;

const Status = styled.div``;

const StatusText = styled.div`
  padding: 10px;
  color: #01426a;
  text-align: center;
  font-weight: bold;
`;

const Value = styled.span`
  font-size: 1.2em;
  font-weight: bold;
`;
