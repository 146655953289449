import React from "react";
const LinkText = (props: any) => {
  return (
    <a
      href={props.to || "#"}
      target="_blank"
      rel="noreferrer"
      title={props.title || ""}
    >
      {props.children}
    </a>
  );
};

export default LinkText;
