import styled from "styled-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useLearnerDetails from "./api/useLearnerDetails";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import Logo from "../../components/Logo";
import Burger from "./components/Burger";
import { Breakpoint_MD } from "../../utils/Constants";
import { Colors } from "../../utils/ColorHelpers";

export const Header = () => {
  const userDetailsFetchObj = useLearnerDetails();
  const navigate = useNavigate();
  const auth = useAuth();
  const [open, setOpen] = useState(false);

  let topNav: any;

  const { t, i18n } = useTranslation();
  const currentLanguageCode =
    i18n && i18n.language && i18n.language.split("-")[0];
  let switchLanguage = "";

  switch (currentLanguageCode) {
    case "en":
      switchLanguage = "Español";
      break;
    case "es":
      switchLanguage = "English";
      break;
  }

  function changeLanguage() {
    if (i18n.language.split("-")[0] === "en") {
      i18n.changeLanguage("es");
    } else {
      i18n.changeLanguage("en");
    }
  }

  let profile = auth && auth.user?.profile;

  if (userDetailsFetchObj.isLoading) {
    topNav = <auro-loader pulse xs />;
  } else {
    topNav = (
      <>
        <MenuItem className="lang" onClick={changeLanguage}>
          {switchLanguage}
        </MenuItem>
        <MenuItem className="divider">|</MenuItem>
        <MenuItem className="user">{profile?.name}</MenuItem>
        <MenuItem className="divider">|</MenuItem>
        <MenuItem className="email">
          <a
            target="_blank"
            href="mailto:training.delivery@alaskaair.com?subject=Feedback%3A%20Initial%20Ramp%20Training%20Portal&body=%5BReplace%20this%20text%20with%20your%20message%20to%20the%20Initial%20Ramp%20Training%20Portal%20administrators.%5D"
            rel="noreferrer"
          >
            <auro-icon category="interface" name="mail">
              Contact Us
            </auro-icon>
          </a>
        </MenuItem>
      </>
    );
  }

  let isTrainer =
    userDetailsFetchObj.data &&
    userDetailsFetchObj.data?.employeeDetails.data &&
    (userDetailsFetchObj.data?.employeeDetails.data.employees[0]
      .isSuperTrainer ||
      userDetailsFetchObj.data?.employeeDetails.data.employees[0].isTrainer);

  return (
    <HeaderContainer>
      <TopLinks showInMobile={false}>{topNav}</TopLinks>
      <Menu>
        <MenuItem className="logo-container">
          <Link to="/">
            <Logo />
          </Link>
          <Burger open={open} setOpen={setOpen} />
        </MenuItem>
        <ShowHideContainer open={open}>
          <MenuItem className={"active"} onClick={() => navigate("/")}>
            Initial Ramp Training
          </MenuItem>
          <MenuItem>
            <SubA href={t("header.journeyMapUrl")} target="_blank">
              {t("header.journeyMap")}
            </SubA>
          </MenuItem>
          <MenuItem>
            <StyledUl>
              <DropDownLi>
                <Dropbtn className="manuals">
                  {t("header.manuals.title")}
                </Dropbtn>
                <DropDownContent>
                  <SubA
                    className="as-manual"
                    href="https://splash.alaskasworld.com/groundops/csm/default.htm"
                    target="_blank"
                  >
                    {t("header.manuals.alaska")}
                  </SubA>
                  <SubA
                    className="qx-manual"
                    target="_blank"
                    href="https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf"
                  >
                    {t("header.manuals.horizon")}
                  </SubA>
                  <SubA
                    className="oo-manual"
                    target="_blank"
                    href="https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full"
                  >
                    {t("header.manuals.skywest")}
                  </SubA>
                </DropDownContent>
              </DropDownLi>
            </StyledUl>
          </MenuItem>
          <MenuItem className="cs-link">
            <SubA
              href="https://aagtraining.csod.com/samldefault.aspx"
              target="_blank"
            >
              Cornerstone
            </SubA>
          </MenuItem>
          <MenuItem className="safety-link">
            <SubA
              target="_blank"
              rel="noreferrer"
              href="https://www.alaskasworld.com/safety/AAG_safety/report_it/default.asp"
            >
              {t("header.safety")}
            </SubA>
          </MenuItem>
          {isTrainer && (
            <MenuItem className="trainer-link">
              <StyledLink to="/trainer">
                {t("header.trainerDashboard")}
              </StyledLink>
            </MenuItem>
          )}
          <TopLinks showInMobile={true}>{topNav}</TopLinks>
        </ShowHideContainer>
        {/* <MenuItem>Safety Alerts</MenuItem> */}
      </Menu>
    </HeaderContainer>
  );
};

interface TopNavProps {
  showInMobile: boolean;
}

const HeaderContainer = styled.div`
  display: grid;
  grid-template-rows: 0.3fr auto;
  grid-template-areas:
    "toprow"
    "menu";
  @media screen and (max-width: ${Breakpoint_MD}) {
    display: block;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
  }
`;

const TopLinks = styled.div<TopNavProps>`
  display: ${({ showInMobile }) => (!showInMobile ? "flex" : "none")};
  min-height: 20px;
  margin-top: 5px;
  justify-content: right;
  @media screen and (max-width: ${Breakpoint_MD}) {
    display: ${({ showInMobile }) => (showInMobile ? "block" : "none")};
    background-color: ${Colors.BgBlue};
  }
`;

const Menu = styled.div`
  display: flex;
  @media screen and (max-width: ${Breakpoint_MD}) {
    display: block;
  }
`;

const ShowHideContainer = styled.div<{ open: boolean }>`
  display: flex;
  @media screen and (max-width: ${Breakpoint_MD}) {
    display: ${({ open }) => (open ? "block" : "none")};
  }
`;

const MenuItem = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  color: #01426a;
  margin: 0px 10px;
  cursor: pointer;

  a {
    color: black;
  }

  &.active {
    font-weight: bold;
    font-size: 1.2em;
  }

  @media screen and (max-width: ${Breakpoint_MD}) {
    background-color: ${Colors.BgBlue};
    margin: 0;
    color: #fff;
    padding-top: 5px;
    a {
      color: #fff;
    }
    &.logo-container {
      border-bottom: solid 1px ${Colors.BgBlue};
      background-color: white;
    }
    &.divider {
      display: none;
    }
  }
`;

const StyledUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`;

const StyledLi = styled.li`
  float: left;
`;

const Dropbtn = styled.div`
  display: inline-block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
`;

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  @media screen and (max-width: ${Breakpoint_MD}) {
    display: inherit;
    background-color: inherit;
    position: inherit;
  }
`;

const DropDownLi = styled(StyledLi)`
  display: inline-block;
  &:hover {
    background-color: var(--auro-color-ui-active-on-light);
    color: white;
  }
  &:hover ${DropDownContent} {
    display: block;
  }
`;

const SubA = styled.a`
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  &:hover {
    background-color: var(--auro-color-ui-bkg-hover-on-light);
  }
`;

const StyledLink = styled(Link)`
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  &:hover {
    background-color: var(--auro-color-ui-bkg-hover-on-light);
  }
`;

export default Header;
