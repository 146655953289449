import { useQuery } from "react-query";
import { useAuth } from "react-oidc-context";
import fetchData from "../../../utils/fetchData";
import { TrainingSummary } from "../../../types/TrainingSummary";

export default function useTrainingSummary(locationId?: string) {
  const auth = useAuth();

  const getTrainingSummary = async () => {
    //if (auth.isAuthenticated && auth.user) {
    const { data } = await fetchData<TrainingSummary>(
      `cornerstone/training-summary`,
      auth.user?.access_token
    );
    console.log("summary data:", data);
    return data;
    //}
  };

  return useQuery(`trainingSummary/${locationId}`, getTrainingSummary);
}
