import React from "react";
import styled from "styled-components";
import { Item } from "../../../types/Config";

export default function ReferenceList({ items }: { items: Item[] }) {
  return (
    <div>
      <ul>
        {items.map((m, i) => {
          return (
            <li key={i}>
              <StyledLink target="_blank" rel="noreferrer" href={m?.url}>
                {m?.text}
              </StyledLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

const StyledLink = styled.a`
  color: #01426a;
`;
