import styled from "styled-components";
import { Colors } from "../../../utils/ColorHelpers";
import { Breakpoint_MD } from "../../../utils/Constants";

interface BurgerProps {
  open: boolean;
  setOpen: Function;
}

const Burger = ({ open, setOpen }: BurgerProps) => {
  const isExpanded = open ? true : false;

  return (
    <StyledBurger
      className="burger-icon"
      aria-label="Toggle menu"
      aria-expanded={isExpanded}
      open={open}
      onClick={() => setOpen(!open)}
    >
      <span />
      <span />
      <span />
    </StyledBurger>
  );
};

export default Burger;

const StyledBurger = styled.button<{ open: boolean }>`
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  display: none;
  @media screen and (max-width: ${Breakpoint_MD}) {
    display: flex;
    position: absolute;
    right: 25px;
  }
  span {
    width: 2rem;
    height: 0.25rem;
    background: ${Colors.BgBlue};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;
