export function getStatusColor(isCompleted: boolean): string {
  return isCompleted ? Colors.Green : Colors.Blue;
}

export const Colors = {
  Green: "var(--auro-color-brand-tropical-400)",
  Blue: "var(--auro-color-brand-midnight-300)",
  Gray: "var(--auro-color-brand-gray-400)",
  BgGray: "var(--auro-color-brand-gray-200)",
  BgBlue: "var(--auro-color-background-darkest)",
};
