import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OtherDiv = styled.div``;

export default function LoadingSpinner() {
  return (
    <StyledDiv>
      <OtherDiv>
        <auro-loader orbit onlight md></auro-loader>
      </OtherDiv>
    </StyledDiv>
  );
}
