import styled from "styled-components";
import CourseRefs from "./CourseRefs";
import { CourseStatus } from "./CourseStatus";
import * as React from "react";
import { Course } from "../../../types/Config";
import { CertificationTransDetailsEntity } from "../../../types/Transcript";
import { Colors } from "../../../utils/ColorHelpers";
import { Breakpoint_MD } from "../../../utils/Constants";
import ImageSection from "../../../components/ImageSection";

export interface ImageProps {
  url: string;
}

export const SubCertification = ({
  courseDetails,
  certifications,
}: {
  courseDetails?: Course;
  certifications?: CertificationTransDetailsEntity[];
}) => {
  let currentCert = certifications?.find(
    (c) => c.CertificateTitle === courseDetails?.certification
  );
  if (courseDetails) {
    return (
      <CertificationContainer>
        <ImageSection
          className="aircraft-img"
          src={courseDetails?.courseImage ? courseDetails?.courseImage : ""}
          title={
            courseDetails && courseDetails.courseTitle
              ? courseDetails.courseTitle
              : ""
          }
        />

        <CertDetails>
          <CourseRefs
            courseDetails={courseDetails}
            certifications={certifications}
            showDescription={true}
          />
          <CourseStatus certification={currentCert} />
        </CertDetails>
      </CertificationContainer>
    );
  } else return null;
};

const CertificationContainer = styled.div`
  display: grid;
  grid-template-rows: 500px auto;
  grid-gap: 1.25rem;
  background-color: ${Colors.BgGray};
  @media screen and (max-width: ${Breakpoint_MD}) {
    grid-template-rows: auto;
  }
`;

const CertDetails = styled.div`
  display: grid;
  grid-template-columns: 55% 45%;
  background-color: #fff;
  padding-top: 20px;

  @media screen and (max-width: ${Breakpoint_MD}) {
    grid-template-columns: auto;
  }
`;
