import styled from "styled-components";
import * as React from "react";
import {
  CertificationTransDetailsEntity,
  SectionItemsEntity,
} from "../../../types/Transcript";
import { useTranslation } from "react-i18next";
import {
  Breakpoint_MD,
  CertificationStatus,
  OJTSuffix,
  SectionItemStatus,
} from "../../../utils/Constants";
import { getStatusColor } from "../../../utils/ColorHelpers";
import StatusCircle from "../../../components/StatusCircle";

export const CourseStatus = ({
  certification,
}: {
  certification?: CertificationTransDetailsEntity;
}) => {
  let { t } = useTranslation();
  let sectionItems: SectionItemsEntity[] = [];
  certification?.Sections?.forEach(
    (s) => (sectionItems = [...sectionItems, ...s.SectionItems])
  );

  let ojt = sectionItems.find((s) => s.ItemTitle.endsWith(OJTSuffix));
  let notOjt = sectionItems.filter((s) => !s.ItemTitle.endsWith(OJTSuffix));

  let ojtStatus =
    ojt?.Status === SectionItemStatus.Completed
      ? t("courseDetails.completed")
      : notOjt.every((s) => s.Status === SectionItemStatus.Completed)
      ? t("courseDetails.ready")
      : t("courseDetails.notReady");

  let itemsRemaining = sectionItems.filter(
    (s) => s.Status !== SectionItemStatus.Completed
  );

  let itemsNotStarted = sectionItems.filter(
    (i) =>
      i.Status !== SectionItemStatus.InProgress &&
      i.Status !== SectionItemStatus.Completed
  );

  let completion = "inProgress";
  if (itemsNotStarted.length === sectionItems.length) {
    completion = "notStarted";
  }
  if (certification?.CertificationStatus === CertificationStatus.Certified)
    completion = "completed";

  return (
    <StatusContainer>
      <StatusCircle
        className="cert-status"
        color={getStatusColor(completion === "completed")}
        label={t("courseDetails.certStatusLabel")}
        value={t(`courseDetails.${completion}`)}
      />
      <StatusCircle
        className="item-status"
        color={getStatusColor(itemsRemaining.length === 0)}
        label={t("courseDetails.itemRemainingLabel")}
        value={itemsRemaining.length.toString()}
      />
      {ojt && (
        <StatusCircle
          className="ojt-status"
          color={getStatusColor(ojt?.Status === SectionItemStatus.Completed)}
          label={t("courseDetails.ojtLabel")}
          value={ojtStatus}
        />
      )}
    </StatusContainer>
  );
};

const StatusContainer = styled.div`
  display: grid;
  padding: 15px 0;
  grid-template-columns: 150px 150px 150px;
  @media screen and (max-width: ${Breakpoint_MD}) {
    grid-template-columns: 110px 110px minmax(auto, 110px);
  }
`;
