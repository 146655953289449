import React from "react";
import styled from "styled-components";
import CurrentLanguageText from "../../../components/CurrentLanguageText";
import { CertificationTransDetailsEntity } from "../../../types/Transcript";
import { getStatusColor } from "../../../utils/ColorHelpers";
import { CertificationStatus } from "../../../utils/Constants";
import { MapSectionProps } from "./CertificationMapSection";
import CourseProgressMapSection from "./CourseProgressMapSection";

const Rectangle = styled.div`
  text-align: center;
`;

const CertificationText = styled.div`
  background: ${(props) => props.color};
  color: #ffffff;
  padding: 10px;
  height: 150px;
`;

const MultiStatusContainer = styled.div`
  display: grid;
  grid-gap: 0.25rem;
  grid-template-rows: 50% 50%;
  background: #f7f7f7;
  padding: 10px;
`;

const BoxHeader = styled.h2`
  text-transform: uppercase;
`;

export default function BoxMapSection({
  section,
  courses,
  certifications,
}: MapSectionProps) {
  let isInProgress = true;
  let sectionCourses = courses.filter((c) => c.sectionId === section.sectionId);

  // Add sub courses
  let subCourses = courses.filter((c) =>
    sectionCourses.some((sc) => sc.courseId === c.parentCourseId)
  );
  //sectionCourses = [...sectionCourses, ...subCourses];

  var currentCertifications: CertificationTransDetailsEntity[] | undefined =
    certifications?.filter(
      (c) =>
        sectionCourses.some((so) => so.certification === c.CertificateTitle) ||
        subCourses.some((so) => so.certification === c.CertificateTitle)
    );

  if (currentCertifications && currentCertifications.length > 0) {
    isInProgress = currentCertifications.some(
      (c) => c.CertificationStatus !== CertificationStatus.Certified
    );
  }

  return (
    <Rectangle className="map-section box-map">
      <CertificationText color={getStatusColor(!isInProgress)}>
        <BoxHeader>
          <CurrentLanguageText text={section.title} />
        </BoxHeader>
        <CurrentLanguageText text={section.description} />
      </CertificationText>
      <MultiStatusContainer>
        {sectionCourses.map((c) => {
          return (
            <CourseProgressMapSection
              key={c.courseId}
              course={c}
              courses={courses}
              certifications={certifications}
              displayStyle={1}
            />
          );
        })}
      </MultiStatusContainer>
    </Rectangle>
  );
}
