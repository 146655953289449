import { useRoutes } from "react-router-dom";
import { Learner } from "../features/learner/routes/Learner";
import { Trainer } from "../features/trainer/routes/Trainer";
import { learnerRoutes } from "./learner";
import * as React from "react";
import { trainerRoutes } from "./trainer";

export const AppRoutes = () => {
  const commonRoutes = [
    { path: "/", element: <Learner /> },
    { path: "/trainer", element: <Trainer /> },
  ];
  const element = useRoutes([
    ...learnerRoutes,
    ...trainerRoutes,
    ...commonRoutes,
  ]);

  return <>{element}</>;
};
