import { sortBy } from "lodash";
import DataTable from "react-data-table-component";
import { RecordsEntity } from "../../../types/TrainingSummary";

const columns = [
  {
    name: "Division",
    sortable: true,
    selector: (row: RecordsEntity) => row.division,
  },
  {
    name: "Name",
    sortable: true,
    selector: (row: RecordsEntity) => row.fullName,
  },
  {
    name: "PeopleSoft Number",
    sortable: true,
    selector: (row: RecordsEntity) => row.userId,
  },
  {
    name: "Location",
    sortable: true,
    selector: (row: RecordsEntity) => row.location,
  },
  {
    name: "Status",
    sortable: true,
    selector: (row: RecordsEntity) => row.trainingStatus,
  },
];

export interface TrainingSummaryTableProps {
  data: RecordsEntity[];
}

export default function TrainingSummaryTable({
  data,
}: TrainingSummaryTableProps) {
  let sortedData = sortBy(data, ["location", "division", "fullName"]);
  return (
    <DataTable columns={columns} data={sortedData} className="summary-table" />
  );
}
