import React from "react";
import styled from "styled-components";
import useCertificationDetails from "../api/useCertificationDetails";
import blueSkyBg from "../../../resources/Blue.png";
import { useTranslation } from "react-i18next";
import CertificationMapSection from "./CertificationMapSection";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Breakpoint_MD } from "../../../utils/Constants";

const SectionContent = styled.div`
  display: grid;
  background: url(${blueSkyBg});
  background-size: cover;
  grid-template-columns: auto 250px 350px 150px 350px 250px auto;
  padding: 10px;
  align-items: center;
  text-align: center;
  @media screen and (max-width: ${Breakpoint_MD}) {
    grid-template-columns: auto;
  }
`;

const LoadingContainer = styled(SectionContent)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function LearnerCertificationMap() {
  const { t } = useTranslation();

  var certificationDetailsFetchObj = useCertificationDetails();

  if (certificationDetailsFetchObj.isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (certificationDetailsFetchObj.isError) {
    return (
      <LoadingContainer>
        <auro-alert type="error">
          <span
            dangerouslySetInnerHTML={{ __html: t("learnerHome.errorMessage") }}
          ></span>
        </auro-alert>
      </LoadingContainer>
    );
  }

  const transcript =
    certificationDetailsFetchObj.data &&
    certificationDetailsFetchObj.data.transcript;
  const config =
    certificationDetailsFetchObj.data &&
    certificationDetailsFetchObj.data.config;

  console.log("config", certificationDetailsFetchObj);
  return (
    <SectionContent>
      {config?.sections?.map((s, i) => {
        return (
          <React.Fragment key={i}>
            <CertificationMapSection
              section={s}
              certifications={transcript?.data[0].CertificationTransDetails}
              courses={config.courses}
            />
            <div>
              {i === 0 || i === config?.sections.length - 2 ? (
                <SeparatorDiv>
                  <img src="/learner/arrow.png" alt="" />
                </SeparatorDiv>
              ) : i !== config?.sections.length - 1 ? (
                <SeparatorDiv>
                  <img src="/learner/plus.png" alt="" />
                </SeparatorDiv>
              ) : null}
            </div>
          </React.Fragment>
        );
      })}
    </SectionContent>
  );
}

const SeparatorDiv = styled.div`
  @media screen and (max-width: ${Breakpoint_MD}) {
    padding: 20px;
    img {
      display: none;
    }
  }
`;
