import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          login: {
            login: "Log In",
            message:
              "You need to sign in to access the Initial Ramp Training Portal.",
          },
          header: {
            manuals: {
              title: "Manuals",
              alaska: "Alaska Airlines Customer Service Manual",
              horizon: "Horizon Air Services Manual",
              skywest: "SkyWest Station Operations Manual SP 5500",
            },
            safety: "Safety",
            journeyMap: "Journey Map",
            journeyMapUrl: "/resources/IRSA-Training-Journey-Map-en.png",
            trainerDashboard: "Trainer Dashboard",
          },
          learnerHome: {
            title: "YOUR RAMP AGENT TRAINING FLIGHT PLAN",

            errorMessage:
              "Oops, something went wrong. Contact <a href='mailto:training.delivery@alaskaair.com'>Alaska Air Group Training Delivery</a> for support.",

            description: {
              intro:
                "<p>The information below shows you each step you will take to become qualified as a ramp agent.  As you make your way through this journey:</p>",

              list1:
                "Click the topics below to open each module and track your journey.",

              list2:
                "Within each module, launch online training and access resources.",

              list3:
                "Finish online training to become eligible for OJT and head outside with your local trainer for on-the-job experience.",

              outro:
                "<p>Not sure what's next? <link1>Click here</link1> to view the Initial Ramp Agent Training Journey Map.</p>",
            },
          },

          courseDetails: {
            thisCourse: "This certification covers:",
            certStatusLabel: "Certification Status",
            itemRemainingLabel: "Items Remaining",
            ojtLabel: "OJT",
            notStarted: "Not Started",
            notAssigned: "Not Assigned",
            inProgress: "In Progress",
            completed: "Completed",
            notReady: "Not Ready",
            ready: "Ready",
            checkYourProgress: "Check your progress",
            references: "References",
            ojtCheckList: "OJT Checklist",
            manuals: "Manuals",
            noItems: "All items completed",
            courseNotFound: "Course not found",
            cornerstoneButtonLabel: "Cornerstone",
            continueInCornerstone:
              "To complete these courses in Cornerstone, <link1>click here</link1>.",
          },
          trainer: {
            title: "RAMP UP YOUR LEARNERS",
            description: {
              intro:
                "<p>This Ramp Trainer dashboard provides you with a snapshot of the progress for each new hire in your location.</p>",
              list1:
                "The IRSA vILT Welcome is required before moving through the rest of the certifications.",
              list2:
                "Each certification provides focused learning with an exam and OJT individualized to each topic.",
              list3:
                "Once a learner has completed ALL ABOUT THE AIRPORT and ALL THINGS BAGGAGE, they are qualified to work in the Bagwell.",
              outro:
                "<p>Click a count below for a list of learners that make up the count.</p>",
            },
            notStartedLabel: "Not Started",
            inProgressLabel: "In Progress",
            pendingOjtLabel: "Pending OJT",
            completedLabel: "Completed",
            lastUpdatedLabel: "Information last updated at",
            printLabel: "Print",
            stationLabel: "Station",
            allLocationsLabel: "All Locations",
          },
        },
      },
      es: {
        translation: {
          // here we will place our translations...
          login: {
            login: "Iniciar sesión",
            message: "Necesita iniciar sesión para acceder a este portal",
          },
          header: {
            manuals: {
              title: "Manuales",
              alaska: "Manual de servicio al cliente de Alaska Airlines",
              horizon: "Manual de servicios de Horizon Air",
              skywest: "Manual de operaciones de la estación SkyWest SP 5500",
            },
            safety: "Seguridad",
            journeyMap: "Mapa del Viaje",
            journeyMapUrl: "/resources/IRSA-Training-Journey-Map-es.png",
            trainerDashboard: "Tablero del Entrenador",
          },
          learnerHome: {
            title: "TU PLAN DE VUELO DE ENTRENAMIENTO COMO AGENTE DE LA RAMPA",
            errorMessage:
              "Huy! Algo salió mal. Comuníquese con <a href='mailto:training.delivery@alaskaair.com'>Alaska Air Group Training Delivery</a> para obtener ayuda.",
            description: {
              intro:
                "<p>La siguiente información le muestra cada paso que dará para calificar como agente de Rampa. Mientras haces tu camino a través de este viaje:</p>",
              list1:
                "Haga clic en los temas abajo para revisar su progreso.",
              list2:
                "Entre cada módulo, pueden lanzar sus entrenamientos y accesar su recursos.",
              list3:
                "Cuando termine sus entrenamientos pueden empezar trabajando con su entrenador de sus aeropuerto. Para poder completar su On-The-Job entrenamiento (OJT).",
              outro:
                "<p>Que sigue? <link1>Haga clic aquí</link1> para ver el mapa de viaje inicial del agente de rampa.</p>",
            },
          },

          courseDetails: {
            thisCourse: "Este curso incluye:",
            certStatusLabel: "Estado de Certificación",
            itemRemainingLabel: "Artículos Pendientes",
            ojtLabel: "OJT",
            notStarted: "No Iniciado",
            notAssigned: "No Asignado",
            inProgress: "En Progresó",
            completed: "Completo",
            notReady: "No Esta Preparado",
            ready: "Preparado",
            checkYourProgress: "Revise Su Progresó",
            references: "Referencias",
            ojtCheckList: "OJT Checklist",
            manuals: "Manuals",
            noItems: "Todos los Elementos Completados",
            courseNotFound: "No Encontrado",
            cornerstoneButtonLabel: "Cornerstone",
            continueInCornerstone:
              "Para completar estos cursos en Cornerstone, <link1>haga clic aquí</link1>.",
          },
          trainer: {
            title: "INVOLUCRAR TUS ESTIDIANTES",
            description: {
              intro:
                "<p>Esta Tablera de Entrenador les dara una foto al progreso de cada uno de sus estudiantes en su aeropuerto.</p>",
              list1:
                "La IRSA vILT WELCOME es requerida antes de mover al resto de las certificaciones.",
              list2:
                "Cada certificacion dara entrenamiento enfocado con un examen y OJT para cada tema.",
              list3:
                "Cuando un estudiante a completado ALL ABOUT THE AIRPORT y ALL THINGS BAGGAGE ellos estaran calificados para trabajar en el Bagwell.",
              outro:
                "<p>Clic un contador abajo para una lista de los estudiantes en esa cuenta.</p>",
            },
            notStartedLabel: "No Iniciado",
            inProgressLabel: "En Progresó",
            pendingOjtLabel: "OJT Pendiente",
            completedLabel: "Completado",
            lastUpdatedLabel: "Información actualizada por última vez a las",
            printLabel: "Imprimir",
            stationLabel: "Estación",
            allLocationsLabel: "Todas las Estaciones",
          },
        },
      },
    },
  });

export default i18n;
