import React from "react";
import { useTranslation } from "react-i18next";

export default function CurrentLanguageText({ text }: { text: any }) {
  const { i18n } = useTranslation();

  const currentLocale = i18n.language.split("-")[0];

  if (text && text[currentLocale]) {
    return <div dangerouslySetInnerHTML={{ __html: text[currentLocale] }} />;
  } else return <div></div>;
}
