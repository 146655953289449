import { useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { SubCertification } from "./SubCertification";
import { Course } from "../../../types/Config";
import { CertificationTransDetailsEntity } from "../../../types/Transcript";
import { Breakpoint_MD } from "../../../utils/Constants";

export interface TabProps {
  color: string;
  isSelected: boolean;
}

export const CertificationList = ({
  courseList,
  certifications,
}: {
  courseList?: Course[];
  certifications?: CertificationTransDetailsEntity[];
}) => {
  const [cert, setSelectedCert] = useState(
    courseList && courseList[0].courseId
  );
  const [tabIndex, setSelectedTabIndex] = useState(0);

  const getSelectedCourse = (): Course | undefined => {
    const course = courseList?.find((c) => c.courseId === cert);
    if (course) {
      return course;
    }
    return courseList && courseList[0];
  };
  let selectedCourse = getSelectedCourse();
  return (
    <>
      <TabContainer color={tabColors[tabIndex]} className="aircraft-tabs">
        {courseList?.map((c, i) => {
          return (
            <Tab
              color={tabColors[i]}
              isSelected={i === tabIndex}
              onClick={() => {
                setSelectedCert(c.courseId);
                setSelectedTabIndex(i);
              }}
            >
              {c.courseTitle}
            </Tab>
          );
        })}
      </TabContainer>

      <SubCertification
        courseDetails={selectedCourse}
        certifications={certifications}
      />
    </>
  );
};

const CommonTabContainer = styled.ul`
  display: inline-block;
  width: 100%;
  list-style-type: none;
  padding: 0px;
  margin: 0;
  margin-bottom: -8px;
  :after {
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  :before {
    z-index: 1;
  }

  @media screen and (max-width: ${Breakpoint_MD}) {
    margin-bottom: 0;
  }
`;

const TabContainer = styled(CommonTabContainer)`
  border-bottom: 10px solid ${(props) => props.color};
`;

const CommonTab = styled.li`
  display: inline-block;
  height: 50px;
  width: 15%;
  color: #ffffff;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  float: left;
  display: block;
  position: relative;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  margin: 0 -5px;
  padding: 0 20px;

  :before {
    position: absolute;
    bottom: -1px;
    width: 6px;
    height: 6px;
    content: " ";
    left: -6px;
    border-bottom-right-radius: 6px;
    border-width: 0 1px 1px 0;
  }

  :after {
    position: absolute;
    bottom: -1px;
    width: 6px;
    height: 6px;
    content: " ";
    right: -6px;
    border-bottom-left-radius: 6px;
    border-width: 0 0 1px 1px;
  }

  @media screen and (max-width: ${Breakpoint_MD}) {
    float: none;
    width: 100%;
    margin: 0;
  }
`;

const Tab = styled(CommonTab)<TabProps>`
  z-index: ${(props) => (props.isSelected ? 2 : 0)};
  background: ${(props) => props.color};
`;

const tabColors = [
  "#01426a",
  "#0074ca",
  "#00c7e6",
  "#B2D37B",
  "#53b390",
  "var(--auro-color-brand-goldcoast-400)",
];

export default CertificationList;
