import * as React from "react";
import { Course } from "../../../types/Config";
import CurrentLanguageText from "../../../components/CurrentLanguageText";
import { Trans, useTranslation } from "react-i18next";
import ReferenceList from "./ReferenceList";
import {
  CertificationTransDetailsEntity,
  SectionItemsEntity,
} from "../../../types/Transcript";
import styled from "styled-components";
import LinkText from "../../../components/LinkText";
import { SectionItemStatus } from "../../../utils/Constants";

export const CourseRefs = ({
  courseDetails,
  certifications,
  showDescription,
}: {
  courseDetails: Course;
  certifications?: CertificationTransDetailsEntity[];
  showDescription: boolean;
}) => {
  let { t } = useTranslation();
  let courseName = courseDetails.certification || courseDetails.courseTitle;

  let currentCert = certifications?.find(
    (c) => c.CertificateTitle === courseDetails.certification
  );

  let sectionItems: SectionItemsEntity[] = [];
  currentCert?.Sections?.forEach(
    (s) => (sectionItems = [...sectionItems, ...s.SectionItems])
  );

  const incompleteItems =
    sectionItems &&
    sectionItems.filter((si) => si.Status !== SectionItemStatus.Completed);

  return (
    <PaddedDiv>
      <div>
        <CurrentLanguageText text={courseDetails.description} />
      </div>
      <div>
        <h3>{t("courseDetails.checkYourProgress")}</h3>
        <div>
          {incompleteItems.length > 0 ? (
            <>
              <CurrentLanguageText text={courseDetails.checkYourProgress} />
              <ul>
                {incompleteItems.map((si) => {
                  return <li>{si.ItemTitle}</li>;
                })}
              </ul>
              {courseDetails.cornerstoneUrl && (
                <Trans
                  i18nKey="courseDetails.continueInCornerstone"
                  components={{
                    link1: (
                      <LinkText
                        to={courseDetails.cornerstoneUrl}
                        title="Cornerstone link"
                        target="_blank"
                        className="cornerstone-link"
                      />
                    ),
                  }}
                />
              )}
            </>
          ) : (
            <div>{t("courseDetails.noItems")}</div>
          )}
        </div>
      </div>
      <div>
        <h3>
          {t("courseDetails.references")} : {courseName}
        </h3>
        <CurrentLanguageText text={courseDetails.references.description} />
        <ReferenceList items={courseDetails.references.items} />
      </div>
    </PaddedDiv>
  );
};

const PaddedDiv = styled.div`
  padding: 40px;
`;

export default CourseRefs;
