import React from "react";
import useTrainingSummary from "../trainer/api/useTrainingSummary";
import { uniq } from "lodash";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function LocationDropdown() {
  const trainingSummaryFetchObj = useTrainingSummary();
  const { location } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (trainingSummaryFetchObj.isLoading) {
    return <auro-loader pulse xs />;
  }

  const locations = trainingSummaryFetchObj.data?.records?.map(
    (r) => r.location
  );

  const uniqueLocations: string[] = uniq(locations);
  return (
    <FloatDiv>
      <CenteringDiv>
        <auro-icon category="interface" name="location-filled" customColor>
          Icon: location
        </auro-icon>
      </CenteringDiv>
      <CenteringDiv>{t("trainer.stationLabel")}:</CenteringDiv>
      <CenteringDiv>
        <StyledSelect
          onChange={(s) => {
            navigate(`/trainer/${s.target.value}`);
          }}
          value={location && ""}
          className="location-drp-down"
        >
          <StyledOption value="">{t("trainer.allLocationsLabel")}</StyledOption>
          {uniqueLocations.map((l) => (
            <StyledOption value={l}>{l}</StyledOption>
          ))}
        </StyledSelect>
      </CenteringDiv>
    </FloatDiv>
  );
}

const CenteringDiv = styled.div`
  display: flex;
  align-items: center;
`;

const FloatDiv = styled.div`
  float: right;
  height: 100%;
  display: flex;
  flex-direction: columns;
  justify-content: right;
  color: white;
  margin: 3px;
`;

const StyledSelect = styled.select`
  padding: 2px;
  background-color: transparent;
  border: none;
  color: white;
`;

const StyledOption = styled.option`
  color: black;
  padding: 5px;
`;
