import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./localization/i18n";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import "@alaskaairux/auro-button";
import "@alaskaairux/auro-header";
import "@alaskaairux/auro-loader";
import "@aurodesignsystem/auro-alert";
import "@alaskaairux/auro-icon";
import "@alaskaairux/auro-accordion";
import Login from "./features/login/pages/Login";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

console.log(process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING);

// appinsights
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING || "",
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const onSigninCallback = (_user: any | void): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig: AuthProviderProps = {
  onSigninCallback: onSigninCallback,
  response_type: "code",
  scope: "openid profile",
  automaticSilentRenew: true,
  loadUserInfo: true,
  authority: process.env.REACT_APP_OIDC_AUTHORITY || "",
  client_id: process.env.REACT_APP_OIDC_CLIENTID || "",
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI || "",
};

let showLogin = true;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.NODE_ENV === "development") {
  showLogin = false;
  const { worker } = require("./mocks/browser");
  worker.start();
}

root.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <AuthProvider {...oidcConfig}>
        <Login showLogin={showLogin}>
          <App />
        </Login>
      </AuthProvider>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
