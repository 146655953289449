import { Config } from "./types/Config";

export const SectionStyles = {
  NO_BOX: 1,
  BOX: 2,
};

const config: Config = {
  certifications: [
    "IRSA Virtual Kickoff",
    "IRSA All About the Airport",
    "IRSA All Things Baggage",
    "IRSA Learn the Turn",
    "IRSA Knowing the Boeing",
    "IRSA All Aboard the Airbus",
    "IRSA Exploring the E175 QX",
    "IRSA Conquering the Q400 QX",
    "IRSA Everything ERJ 175 OO",
    "IRSA Fundamentals of the Freighter",
    "IRSA Virtual Wrap-Up",
  ],
  sections: [
    {
      sectionId: 1,
      sectionStyle: SectionStyles.NO_BOX,
    },
    {
      sectionId: 2,
      sectionStyle: SectionStyles.BOX,
      title: {
        en: "Baggage",
        es: "Equipaje",
      },
      description: {
        en: "Complete ALL ABOUT THE AIRPORT and ALL THINGS BAGGAGE to be able to work in the Bagwell.",
        es: "Complete ALL ABOUT THE AIRPORT y ALL THINGS BAGGAGE para poder trabajar en el Bagwell.",
      },
    },
    {
      sectionId: 3,
      sectionStyle: SectionStyles.BOX,
      title: {
        en: "Ramp",
        es: "Rampa",
      },
      description: {
        en: "Complete required elements in all four modules to be certified as a ramp agent.",
        es: "Complete los elementos requeridos en los cuatro módulos para obtener la certificación como agente de rampa.",
      },
    },
    {
      sectionId: 4,
      sectionStyle: SectionStyles.NO_BOX,
    },
  ],
  courses: [
    {
      courseId: 1,
      parentCourseId: null,
      sectionId: 1,
      certification: "IRSA Virtual Kickoff",
      description: {
        en: "IRSA Virtual Kickoff starts your learning journey with a virtual introduction to the ramp agent role. Register for a virtual session using the button above. Please note that this page does not show your registration details. Check your email for confirmation details of your virtual session, or if you are uncertain if you have already registered for a session. Reach out to your location station trainer with questions or concerns.",
        es: "IRSA Virtual Kickoff comienza tu viaje de enseñanza con una introducción virtual al rol del agente de rampa. Regístrese para una sesión virtual usando el botón de arriba. Tome en cuenta que esta página no muestra sus datos de registro. Consulte su correo electrónico para ver los detalles de confirmación de su sesión virtual, o si no está seguro que se ha registrado para una sesión. Comuníquese con el entrenador de su estación si tiene preguntas o dudas.",
      },
      courseButton: {
        link: "https://outlook.office365.com/owa/calendar/VirtualTrainingSessions@alaskaair.onmicrosoft.com/bookings/",
        text: {
          en: "Register Now",
          es: "Regístrese Ahora",
        },
      },
      courseModules: [
        {
          en: "Expectations for the IRSA program.",
          es: "Expectativas para el programa de IRSA.",
        },
        {
          en: "Ramp agent roles and responsibilities.",
          es: "Responsabilidades del agente de rampa.",
        },
        {
          en: "Alaska Air Group safety overview.",
          es: "Visión general de seguridad de Alaska Air Group.",
        },
        {
          en: "Regulatory compliance items.",
          es: "Elementos de cumplimiento normativo.",
        },
        {
          en: "Resources and next steps in the program.",
          es: "Recursos y próximos pasos en el programa.",
        },
      ],
      icon: {
        default: "/resources/vILT-Welcome-Blue.svg",
        completion: "/resources/vILT-Welcome-Green.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
    {
      courseId: 2,
      parentCourseId: null,
      sectionId: 2,
      certification: "IRSA All About the Airport",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1282",
      description: {
        en: "Your role as a ramp agent is critical to ensure a safe and secure environment on the ramp. In ALL ABOUT THE AIRPORT, you will learn the day to day policies and procedures required to successfully work on an airport ramp.",
        es: "Su función como agente de rampa es importante para garantizar un ambiente seguro en la rampa. En ALL ABOUT THE AIRPORT, aprenderá las directivas y los tramites diarios necesarios para trabajar en una rampa del aeropuerto.",
      },
      courseModules: [
        {
          en: "Airport Safety & Security",
          es: "Seguridad y Protección del Aeropuerto",
        },
        {
          en: "Ground Support Equipment (GSE)",
          es: "Equipo de la Rampa (GSE)",
        },
        {
          en: "Environmental Awareness & Emergency Response",
          es: "Conciencia Ambiental y Respuesta a Emergencias",
        },
      ],
      icon: {
        default: "/resources/All-About-The-Airport-Blue.svg",
        completion: "/resources/All-About-The-Airport-Green.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "ALL ABOUT THE AIRPORT OJT Checklist",
            url: "https://www.alaskasworld.com/groundops/resource/Airport_OJT_Checklist.pdf",
          },
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
    {
      courseId: 3,
      parentCourseId: null,
      sectionId: 2,
      certification: "IRSA All Things Baggage",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1283",
      description: {
        en: "ALL THINGS BAGGAGE will take a deeper dive into the journey of a bag and the fundamentals of baggage handling.",
        es: "ALL THINGS BAGGAGE demostrara el viaje de una maleta y los fundamentos del manejo de equipaje",
      },
      courseModules: [
        { en: "Ramp Safety", 
          es: "Seguridad en la Rampa" },
        {
          en: "Baggage and Aircraft Safety",
          es: "Seguridad del Equipaje y Aeronaves",
        },
        { en: "Lifting Safety", 
          es: "Seguridad en Manejo de Equipaje" },
        {
          en: "City and Commodity Codes, Tags & Labels",
          es: "Códigos de Ciudad y Codigos de Productos, Etiquetas",
        },
        { en: "Dangerous Goods", 
          es: "Mercancías Peligrosas" },
      ],
      icon: {
        default: "/resources/All-Things-Baggage-Blue.svg",
        completion: "/resources/All-Things-Baggage-Green.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "ALL THINGS BAGGAGE OJT Checklist",
            url: "https://www.alaskasworld.com/groundops/resource/Baggage_OJT_Checklist.pdf",
          },
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
    {
      courseId: 4,
      parentCourseId: null,
      sectionId: 3,
      certification: "IRSA Learn the Turn",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1284",
      description: {
        en: "The LEARN THE TURN module includes training on timelines, the arrival, the download, the upload, and the departure of the aircraft. These key processes, when followed, ensure a safe work environment for everyone involved in the aircraft turn. ",
        es: "El módulo LEARN THE TURN incluye capacitación sobre la línea de tiempo, la llegada, la descarga, la carga y la salida de la aeronave. Estos procesos clave, cuando se siguen, garantizan un entorno de trabajo seguro para todos los involucrados en el giro de la aeronave.",
      },
      courseModules: [
        {
          en: "Aircraft Arrival and Departure",
          es: "Llegada y Salida de Aeronaves",
        },
        { en: "Unloading and Loading", es: "Descarga y Carga" },
        { en: "Marshalling Basics", es: "Conceptos Básicos de Clasificación" },
      ],
      icon: {
        default: "/resources/Learn-the-Turn-Blue.svg",
        completion: "/resources/Learn-the-Turn-Green.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
          {
            text: "SkyWest Operations Manual SP 5500 Chapter 05 Aircraft Movement",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Ch05_Full",
          },
        ],
      },
    },
    {
      courseId: 5,
      parentCourseId: null,
      sectionId: 3,
      certification: null,
      courseTitle: "Aircraft",
      description: {
        en: "Alaska Air Group operates multiple aircraft types, and aircraft training is automatically assigned to you based on flight activity in your station. Click on individual aircraft headers to review your progress and to complete the courses in Cornerstone.",
        es: "Alaska Air Group opera múltiples tipos de aeronaves, y el entrenamiento de aeronaves se le asigna automáticamente según la actividad de vuelo en su estación. Haga clic en los encabezados de aviones individuales para revisar su progreso y completar los cursos en Cornerstone.",
      },
      icon: {
        default: "/resources/Aircraft-Blue.svg",
        completion: "/resources/Aircraft-Green.svg",
      },
      checkYourProgress: {
        en: "",
        es: "",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
          {
            text: "",
            url: "",
          },
        ],
      },
    },
    {
      courseId: 6,
      parentCourseId: 5,
      sectionId: null,
      certification: "IRSA Knowing the Boeing",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1285",
      courseTitle: "Boeing",
      courseImage: "/resources/boeing.png",
      description: {
        en: "Alaska Airlines remains focused on improving both passenger comfort and our fuel efficiency. We maintain a young operational fleet of aircraft. To learn more about each aircraft type <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>click here</a>.",
        es: "Alaska Airlines sigue enfocado en mejorar tanto la comodidad de los pasajeros como nuestra eficiencia de gasolina. Mantenemos una flota operativa bien mantenidas de aeronaves. Para obtener más información sobre cada tipo de avión, haga <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>clic aquí</a>.",
      },
      icon: {
        default: "/resources/Boeing-Blue.svg",
        completion: "/resources/Boeing-Blue.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "KNOWING THE BOEING OJT Checklist",
            url: "https://www.alaskasworld.com/groundops/resource/737_OJT_Checklist.pdf",
          },
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
    {
      courseId: 7,
      parentCourseId: 5,
      sectionId: null,
      certification: "IRSA All Aboard the Airbus",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1286",
      courseTitle: "Airbus",
      courseImage: "/resources/airbus.png",
      description: {
        en: "Alaska Airlines remains focused on improving both passenger comfort and our fuel efficiency. We maintain a young operational fleet of aircraft. To learn more about each aircraft type <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>click here</a>.",
        es: "Alaska Airlines sigue enfocado en mejorar tanto la comodidad de los pasajeros como nuestra eficiencia de gasolina. Mantenemos una flota operativa bien mantenidas de aeronaves. Para obtener más información sobre cada tipo de avión, haga <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>clic aquí</a>.",
      },
      icon: {
        default: "/resources/Airbus-Blue.svg",
        completion: "/resources/Airbus-Blue.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "ALL ABOARD THE AIRBUS OJT Checklist",
            url: "https://www.alaskasworld.com/groundops/resource/Airbus_OJT_Checklist.pdf",
          },
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
    {
      courseId: 8,
      parentCourseId: 5,
      sectionId: null,
      certification: "IRSA Exploring the E175 QX",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1287",
      courseTitle: "E175 (QX)",
      courseImage: "/resources/e175QX.png",
      description: {
        en: "Alaska Airlines remains focused on improving both passenger comfort and our fuel efficiency. We maintain a young operational fleet of aircraft. To learn more about each aircraft type <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>click here</a>.",
        es: "Alaska Airlines sigue enfocado en mejorar tanto la comodidad de los pasajeros como nuestra eficiencia de gasolina. Mantenemos una flota operativa bien mantenidas de aeronaves. Para obtener más información sobre cada tipo de avión, haga <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>clic aquí</a>.",
      },
      icon: {
        default: "/resources/E175-QX.svg",
        completion: "/resources/E175-QX.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "EXPLORING THE E175 QX OJT Checklist",
            url: "https://www.alaskasworld.com/groundops/resource/E175_OJT_Checklist.pdf",
          },
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
    {
      courseId: 10,
      parentCourseId: 5,
      sectionId: null,
      certification: "IRSA Everything ERJ 175 OO",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1289",
      courseTitle: "ERJ 175 (OO)",
      courseImage: "/resources/e175OO.png",
      description: {
        en: "Alaska Airlines remains focused on improving both passenger comfort and our fuel efficiency. We maintain a young operational fleet of aircraft. To learn more about each aircraft type <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>click here</a>.",
        es: "Alaska Airlines sigue enfocado en mejorar tanto la comodidad de los pasajeros como nuestra eficiencia de gasolina. Mantenemos una flota operativa bien mantenidas de aeronaves. Para obtener más información sobre cada tipo de avión, haga <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>clic aquí</a>.",
      },
      icon: {
        default: "/resources/ERJ175-OO.svg",
        completion: "/resources/ERJ175-OO.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "EXPLORING THE ERJ 175 OO OJT Checklist",
            url: "https://www.alaskasworld.com/groundops/resource/ERJ175_OJT_Checklist.pdf",
          },
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
          {
            text: "SkyWest Station Operations Manual SP 5500 Chapter 08 Section 03 Embraer Regional Jet (ERJ) 175",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Ch08_Sec03",
          },
        ],
      },
    },
    {
      courseId: 9,
      parentCourseId: 5,
      sectionId: null,
      certification: "IRSA Conquering the Q400 QX",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1288",
      courseTitle: "Q400",
      courseImage: "/resources/Q400.png",
      description: {
        en: "Alaska Airlines remains focused on improving both passenger comfort and our fuel efficiency. We maintain a young operational fleet of aircraft. To learn more about each aircraft type <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>click here</a>.",
        es: "Alaska Airlines sigue enfocado en mejorar tanto la comodidad de los pasajeros como nuestra eficiencia de gasolina. Mantenemos una flota operativa bien mantenidas de aeronaves. Para obtener más información sobre cada tipo de avión, haga <a href='https://www.alaskaair.com/content/travel-info/our-aircraft' target='_blank'>clic aquí</a>.",
      },
      icon: {
        default: "/resources/Q400-Blue.svg",
        completion: "/resources/Q400-Blue.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "CONQUERING THE Q400 QX OJT Checklist",
            url: "https://www.alaskasworld.com/groundops/resource/Q400_OJT_Checklist.pdf",
          },
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
    {
      courseId: 12,
      parentCourseId: 5,
      sectionId: null,
      certification: "IRSA Fundamentals of the Freighter",
      cornerstoneUrl:
        "https://aagtraining.csod.com/samldefault.aspx?ReturnUrl=https%3a%2f%2faagtraining.csod.com%2fLMS%2fCertification%2fCertificationDetails.aspx%3fcertid%3d1293",
      courseTitle: "Freighter",
      courseImage: "/resources/freighter.png",
      description: {
        en: "We're the only passenger airline in the U.S. with dedicated cargo planes. Our fleet of 737-700 freighters deliver flexibility and capability to, from and within the state of Alaska. To learn more about our freighter aircraft, <a href='https://www.alaskacargo.com/Fleet/freighter' target='_blank'>click here</a>.",
        es: "Somos la única aerolínea de pasajeros en los Estados Unidos con aeronaves de carga exclusivos. Nuestras flotas de aeronaves de carga 737-700 ofrece flexibilidad y capacidad desde y dentro del estado de Alaska. Para obtener más información sobre cada typo de avion, haga <a href='https://www.alaskacargo.com/Fleet/freighter' target='_blank'>clic aquí</a>.",
      },
      icon: {
        default: "/resources/Freighter-Blue.svg",
        completion: "/resources/Freighter-Blue.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "Fundamentals of the Freighter OJT Checklist",
            url: "https://www.alaskasworld.com/groundops/resource/Freighter_OJT_Checklist.pdf",
          },
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol5/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
    {
      courseId: 11,
      parentCourseId: null,
      sectionId: 4,
      certification: "IRSA Virtual Wrap-Up",
      description: {
        en: "IRSA Virtual Wrap-Up concludes your learning journey with a virtual check-in. Register for a virtual session using the button above. Please note that this page does not show your registration details. Check your email for confirmation details of your virtual session, or if you are uncertain if you have already registered for a session. Reach out to your location station trainer with questions or concerns.",
        es: "IRSA Virtual Wrap-Up concluye su viaje de enseñanza con un registro virtual. Regístrese para una sesión virtual usando el botón de arriba. Tome en cuenta que esta página no muestra sus datos de registro. Consulte su correo electrónico para ver los detalles de confirmación de su sesión virtual, o si no está seguro que se ha registrado para una sesión. Comuníquese con el entrenador de su estación si tiene preguntas o dudas.",
      },
      courseButton: {
        link: "https://outlook.office365.com/owa/calendar/IRSAVILTWrapUp@alaskaair.onmicrosoft.com/bookings/",
        text: {
          en: "Register Now",
          es: "Regístrese Ahora",
        },
      },
      courseModules: [
        {
          en: "Review Alaska Air Group’s culture and safety.",
          es: "Revisar la cultura y la seguridad de Alaska Air Group.",
        },
        {
          en: "Review the overall experience in the IRSA program.",
          es: "Revisar la experiencia general en el programa IRSA",
        },
      ],
      icon: {
        default: "/resources/vILT-Wrap-Up-Blue.svg",
        completion: "/resources/vILT-Wrap-Up-Green.svg",
      },
      checkYourProgress: {
        en: "Remaining training in progress:",
        es: "Elementos en progreso del curso:",
      },
      references: {
        description: {
          en: "",
          es: "",
        },
        items: [
          {
            text: "Alaska Airlines Customer Service Manual",
            url: "https://www.alaskasworld.com/groundops/csm/vol3/",
          },
          {
            text: "Horizon Air Services Manual",
            url: "https://splash.onyourhorizon.com/Teams/Customer_Services/Services_Training/Manuals/SM0a2.pdf",
          },
          {
            text: "SkyWest Operations Manual SP 5500",
            url: "https://skywestonline.com/SKYW/OnlineDocs/?docid=SP5500_Full",
          },
        ],
      },
    },
  ],
};

export default config;
