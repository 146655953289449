import * as React from "react";
import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Trainer } from "../features/trainer/routes/Trainer";

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="h-full w-full flex items-center justify-center">
          Loading...
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
};
export const trainerRoutes = [
  {
    path: "/trainer",
    element: <App />,
    children: [
      { path: "/trainer/:location", element: <Trainer /> },
      { path: "/trainer", element: <Trainer /> },
      { path: "*", element: <Navigate to="." /> },
    ],
  },
];
