export const CertificationStatus = {
  Certified: "Certified",
  InProgress: "In Progress",
};

export const SectionItemStatus = {
  Completed: "Completed",
  NotActivated: "Not Activated",
  InProgress: "In Progress",
  Approved: "Approved",
  Registered: "Registered",
};

// Layout breakpoints
export const Breakpoint_SM = "660px";
export const Breakpoint_MD = "999px";
export const Breakpoint_LG = "1232px";

export const OJTSuffix = process.env.REACT_APP_OJT_SUFFIX || "OJT";

export const TrainerGroupIds: number[] =
  process.env.REACT_APP_TRAINER_GROUP_IDS?.split(",").map((i) => parseInt(i)) ||
  []; //[8406];
export const TrainerGroupIds_AllLocations: number[] =
  process.env.REACT_APP_AL_LOCATION_TRAINER_GROUP_IDS?.split(",").map((i) =>
    parseInt(i)
  ) || [];
