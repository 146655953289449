import styled from "styled-components";
import blueSkyBg from "../resources/Blue.png";

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

export default LoadingContainer;
