import React from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import styled from "styled-components";
import Logo from "../../../components/Logo";

export default function Login(
  props: React.PropsWithChildren<{ showLogin: boolean }>
): React.ReactElement | null {
  const auth = useAuth();
  const { t } = useTranslation();

  if (!props.showLogin || auth.isAuthenticated) {
    return <>{props.children}</>;
  }

  let formElements: React.ReactElement | null = null;

  return (
    <LoginContainer>
      <LoginForm>
        <CenteringContainer>
          {formElements || (
            <>
              <Logo />
              <auro-header level="4" display="300">
                {t("login.message")}
              </auro-header>
              <auro-button
                onClick={() => auth.signinRedirect()}
                class="login-btn"
              >
                {t("login.login")}
              </auro-button>
            </>
          )}
        </CenteringContainer>
      </LoginForm>
    </LoginContainer>
  );
}

const CenteringContainer = styled.div`
  text-align: center;
`;

const LoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: var(--auro-color-background-darker);
  justify-content: center;
  align-items: center;
`;

const LoginForm = styled.div`
  background-color: var(--auro-color-background-lighter);
  max-width: 400px;
  border: solid 0px var(--auro-color-border-active-on-light);
  border-radius: 20px;
  padding: 20px;
`;
