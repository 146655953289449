import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Course } from "../../../types/Config";
import { CertificationTransDetailsEntity } from "../../../types/Transcript";
import { useTranslation } from "react-i18next";
import { Colors, getStatusColor } from "../../../utils/ColorHelpers";
import getCourseStatus from "../utils/getCourseStatus";
import { Breakpoint_MD } from "../../../utils/Constants";

const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(100px, auto) 150px;
  text-align: center;
  align-items: center;
  justify-items: center;
  @media screen and (max-width: ${Breakpoint_MD}) {
    grid-template-columns: auto auto;
  }
`;

const PlainStatusContainer = styled(StatusContainer)`
  display: block;
`;

const Image = styled.img`
  width: 160px;
`;

const StatusText = styled.div`
  text-transform: uppercase;
  color: ${(props) => props.color};
  font-weight: bold;
`;

export interface CourseProgressMapSectionProps {
  course: Course;
  courses: Course[];
  certifications?: CertificationTransDetailsEntity[];
  displayStyle: 0 | 1;
}

export default function CourseProgressMapSection({
  course,
  courses,
  certifications,
  displayStyle,
}: CourseProgressMapSectionProps) {
  const { t } = useTranslation();

  const { assigned, isInProgress, courseStatusText } = getCourseStatus(
    course,
    courses,
    t,
    certifications
  );

  let children = (
    <>
      {assigned ? (
        <Link to={assigned ? `/learner/course/${course.courseId}` : ""}>
          <Image
            src={isInProgress ? course.icon?.default : course.icon?.completion}
            title={course.courseTitle || course.certification || ""}
          />
        </Link>
      ) : (
        <Image
          src={
            isInProgress || !assigned
              ? course.icon?.default
              : course.icon?.completion
          }
          title={course.courseTitle || course.certification || ""}
        />
      )}
      <StatusText
        color={assigned ? getStatusColor(!isInProgress) : Colors.Gray}
        className="status"
      >
        {courseStatusText}
      </StatusText>
    </>
  );

  return displayStyle ? (
    <StatusContainer className="course">{children}</StatusContainer>
  ) : (
    <PlainStatusContainer className="course">{children}</PlainStatusContainer>
  );
}
