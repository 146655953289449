import { useQuery } from "react-query";
import config from "../../../configuration";
import { Transcript } from "../../../types/Transcript";
import { useAuth } from "react-oidc-context";
import fetchData from "../../../utils/fetchData";

export default function useCertificationDetails() {
  const auth = useAuth();

  const getCertifications = async () => {
    //if (auth.isAuthenticated && auth.user) {
    const { data } = await fetchData<Transcript>(
      "cornerstone/transcripts",
      auth.user?.access_token
    );
    console.log("cert data:", data);
    data.data[0].CertificationTransDetails =
      data.data[0].CertificationTransDetails.filter((c) =>
        config.certifications.some((co) => co === c.CertificateTitle)
      );
    return { transcript: data, config };
    //}
  };

  return useQuery("certifications", getCertifications);
}
