import * as React from "react";
import { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CourseDetail } from "../features/learner/routes/Course";
import { Learner } from "../features/learner/routes/Learner";

const App = () => {
  return (
    <Suspense
      fallback={
        <div className="h-full w-full flex items-center justify-center">
          Loading...
        </div>
      }
    >
      <Outlet />
    </Suspense>
  );
};
export const learnerRoutes = [
  {
    path: "/learner",
    element: <App />,
    children: [
      { path: "/learner/course/:courseId", element: <CourseDetail /> },
      { path: "/learner", element: <Learner /> },
      { path: "*", element: <Navigate to="." /> },
    ],
  },
];
