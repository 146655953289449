import { useQuery } from "react-query";
import { Groups, UserDetails } from "../../../types/UserDetails";
import fetchData from "../../../utils/fetchData";
import { useAuth } from "react-oidc-context";
import {
  TrainerGroupIds,
  TrainerGroupIds_AllLocations,
} from "../../../utils/Constants";

export interface LearnerDetails {
  employeeDetails: UserDetails;
  groupDetails: Groups;
}

export default function useLearnerDetails() {
  const auth = useAuth();

  const getLearnerDetails = async () => {
    //if (auth.isAuthenticated && auth.user) {
    const { data } = await fetchData<LearnerDetails>(
      "cornerstone/user-details",
      auth.user?.access_token
    );

    const isTrainer = data?.groupDetails.data?.some((g) =>
      TrainerGroupIds.some((t) => t === g.id)
    );
    const isSuperTrainer = data?.groupDetails.data?.some((g) =>
      TrainerGroupIds_AllLocations.some((t) => t === g.id)
    );

    if (data && data?.employeeDetails.data && data?.employeeDetails.data) {
      data.employeeDetails.data.employees[0].isTrainer = isTrainer;
      data.employeeDetails.data.employees[0].isSuperTrainer = isSuperTrainer;
    }

    return data;
    //}
  };

  return useQuery("user-details", getLearnerDetails);
}
