import React, { useState } from "react";
import { Course } from "../../../types/Config";
import styled from "styled-components";
import StatusCircle from "../../../components/StatusCircle";
import { Colors } from "../../../utils/ColorHelpers";
import { CertSummaryItem, UserSummaryItem } from "./TrainerDashboard";
import TrainingSummaryTable from "./TrainingSummaryTable";
import { useTranslation } from "react-i18next";

export default function CertificationSummaryWidget({
  item,
  course,
}: {
  item: CertSummaryItem;
  course?: Course;
}) {
  const { t } = useTranslation();
  const [currentStatus, setCurrentStatus] = useState("");

  let summaryItems: UserSummaryItem[] = [];

  if (currentStatus === t("trainer.notStartedLabel")) {
    summaryItems = item.notStarted;
  } else if (currentStatus === t("trainer.inProgressLabel")) {
    summaryItems = item.inProgress;
  } else if (currentStatus === t("trainer.pendingOjtLabel")) {
    summaryItems = item.pendingOjt;
  }

  const summaryData = summaryItems.map((u) => {
    return { ...u.items[0], trainingStatus: currentStatus };
  });

  function onWidgetClick(status: string) {
    // toggle display of summary table by clearing status
    if (currentStatus === status) {
      setCurrentStatus("");
    } else {
      setCurrentStatus(status);
    }
  }
  function getStatusColor(label: string) {
    if (label === currentStatus) return Colors.Blue;
    else return Colors.Gray;
  }

  const isVILT = item.certification.toLowerCase().indexOf("vilt") >= 0;
  const viltClass = isVILT ? "vilt" : "";

  return (
    <>
      <Wrapper slot="trigger">
        <PlainCell>
          <StyledImg src={course?.icon?.default} />
        </PlainCell>
        <SpacerCell></SpacerCell>
        <Cell
          className="course-summary"
          onClick={() => onWidgetClick(t("trainer.notStartedLabel"))}
        >
          <StatusCircle
            value={item.notStarted.length.toString()}
            label={t("trainer.notStartedLabel")}
            labelPosition="inside"
            color={getStatusColor(t("trainer.notStartedLabel"))}
          />
        </Cell>
        <SpacerCell></SpacerCell>
        <Cell
          className={`course-summary ${viltClass}`}
          onClick={() => onWidgetClick(t("trainer.inProgressLabel"))}
        >
          <StatusCircle
            value={item.inProgress.length.toString()}
            label={t("trainer.inProgressLabel")}
            labelPosition="inside"
            color={getStatusColor(t("trainer.inProgressLabel"))}
          />
        </Cell>
        <SpacerCell></SpacerCell>
        <Cell
          className={`course-summary ${viltClass}`}
          onClick={() => onWidgetClick(t("trainer.pendingOjtLabel"))}
        >
          <StatusCircle
            value={item.pendingOjt.length.toString()}
            label={t("trainer.pendingOjtLabel")}
            labelPosition="inside"
            color={getStatusColor(t("trainer.pendingOjtLabel"))}
          />
        </Cell>
      </Wrapper>
      <div>
        {currentStatus !== "" && <TrainingSummaryTable data={summaryData} />}
      </div>
    </>
  );
}

const Cell = styled.div`
  z-index: 1;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 5px dashed ${Colors.Blue};
  cursor: pointer;
  width: 145px;
`;

const PlainCell = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledImg = styled.img`
  width: 145px;
`;

const SpacerCell = styled.div``;

const Wrapper = styled.div`
  text-align: center;
  margin: 20px;
  position: relative;
  display: grid;
  grid-template-columns: 145px auto 145px auto 145px auto 145px;
  :after {
    content: "";
    position: absolute;
    top: 50%;
    right: 145px;
    left: 145px;
    z-index: 0;
    border-top: 5px dashed ${Colors.Blue};
    transform: translateY(-50%);
  }
`;
