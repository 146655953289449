import axios from "axios";
import getAuthHeaders from "./getAuthHeaders";

function fetchData<T>(url: string, access_token: string | undefined) {
  return axios.get<T>(`${process.env.REACT_APP_API_END_POINT || ""}${url}`, {
    headers: getAuthHeaders(access_token),
  });
}

export default fetchData;
