import * as React from "react";
import styled from "styled-components";
import trainerHome from "../assets/trainer_home.png";
import trainerBg from "../assets/trainer-bg.png";
import { useTranslation, Trans } from "react-i18next";
import LinkText from "../../../components/LinkText";
import TrainerDashboard from "../components/TrainerDashboard";
import useLearnerDetails from "../../Header/api/useLearnerDetails";
import LoadingContainer from "../../../components/LoadingContainer";
import ImageSection from "../../../components/ImageSection";

export const TrainerHome = () => {
  const { t } = useTranslation();
  const userDetailsFetchObj = useLearnerDetails();
  let isTrainer =
    userDetailsFetchObj.data &&
    userDetailsFetchObj.data?.employeeDetails.data &&
    (userDetailsFetchObj.data?.employeeDetails.data.employees[0]
      .isSuperTrainer ||
      userDetailsFetchObj.data?.employeeDetails.data.employees[0].isTrainer);

  if (userDetailsFetchObj.data && !isTrainer) {
    return (
      <LoadingContainer>
        <auro-alert>
          You do not have access. Only Trainers can acccess the dashboard
        </auro-alert>
      </LoadingContainer>
    );
  }

  return (
    <TrainerContainer>
      <SectionIntro>
        <PageIntro>
          <PageIntroContent>
            <PageIntroHeader>{t("trainer.title")}</PageIntroHeader>
            <Trans i18nKey="trainer.description.intro"></Trans>
            <ul>
              <li>{t("trainer.description.list1")}</li>
              <li>{t("trainer.description.list2")}</li>
              <li>{t("trainer.description.list3")}</li>
            </ul>
            <Trans
              i18nKey="trainer.description.outro"
              components={{
                link1: (
                  <LinkText
                    to={"resources/IRSA-Training-Journey-Map.png"}
                    title="Training map"
                    target="_blank"
                    className="training-map"
                  ></LinkText>
                ),
              }}
            ></Trans>
            <StyledButton onClick={() => window.print()}>
              <div>{t("trainer.printLabel")} </div>
              <div>
                <auro-icon
                  customcolor
                  category="interface"
                  name="printer"
                ></auro-icon>
              </div>
            </StyledButton>
          </PageIntroContent>
          <ImageSection src={trainerHome} />
        </PageIntro>
      </SectionIntro>
      <SectionContent>
        <TrainerDashboard />
      </SectionContent>
    </TrainerContainer>
  );
};

const TrainerContainer = styled.div`
  display: grid;
  grid-template-rows: 0.3fr auto;
  grid-gap: 1.25rem;
  grid-template-areas:
    "intro"
    "content";
  background: #c8c9c7;
`;

const SectionIntro = styled.div`
  display: grid;
  background: #ffffff;
`;

const SectionContent = styled.div`
  display: flex;
  background: url(${trainerBg}) no-repeat;
  background-color: #c3def1;
  padding: 10px;
  align-items: center;
  text-align: center;
`;

const PageIntro = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const PageIntroHeader = styled.h2`
  color: rgba(0, 116, 200, 1);
`;

const PageIntroContent = styled.div`
  padding: 20px;
  color: #01426a;
`;

const StyledButton = styled.div`
  height: 50px;
  width: 200px;
  cursor: pointer;
  background: #00c7e6;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    padding: 3px;
  }
`;

export default TrainerHome;
