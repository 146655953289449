import styled from "styled-components";
import React from "react";
import { useLocation } from "react-router-dom";
import useLearnerDetails from "./api/useLearnerDetails";
import LocationDropdown from "./LocationDropdown";
import { Colors } from "../../utils/ColorHelpers";

export default function WelcomeBanner() {
  const location = useLocation();
  const userDetailsFetchObj = useLearnerDetails();

  if (userDetailsFetchObj.isLoading) return <Banner />;

  let isSuperTrainer =
    userDetailsFetchObj.data &&
    userDetailsFetchObj.data?.employeeDetails.data &&
    userDetailsFetchObj.data.employeeDetails.data.employees[0].isSuperTrainer;

  const showLocationDropdown =
    location.pathname.toLocaleLowerCase().indexOf("trainer") >= 0 &&
    isSuperTrainer;

  return <Banner>{showLocationDropdown && <LocationDropdown />}</Banner>;
}

const Banner = styled.div`
  height: 30px;
  background-color: ${Colors.BgBlue};
  background-size: cover;
  color: #ffffff;
`;
