import React from "react";
import styled from "styled-components";
import LoadingContainer from "../../../components/LoadingContainer";
import LoadingSpinner from "../../../components/LoadingSpinner";
import useTrainingSummary from "../api/useTrainingSummary";
import config from "../../../configuration";
import { groupBy, forOwn } from "lodash";
import { OJTSuffix, SectionItemStatus } from "../../../utils/Constants";
import { RecordsEntity } from "../../../types/TrainingSummary";
import { useParams } from "react-router-dom";
import useLearnerDetails from "../../Header/api/useLearnerDetails";
import CertificationSummaryWidget from "./CertificationSummaryWidget";
import { useTranslation } from "react-i18next";

export interface UserSummaryItem {
  id: string;
  items: RecordsEntity[];
}

export interface CertSummaryItem {
  certification: string;
  notStarted: UserSummaryItem[];
  inProgress: UserSummaryItem[];
  pendingOjt: UserSummaryItem[];
  completed: UserSummaryItem[];
}

export default function TrainerDashboard() {
  const trainingSummaryFetchObj = useTrainingSummary();
  const { location } = useParams();
  const userDetailsFetchObj = useLearnerDetails();
  const { t } = useTranslation();

  if (trainingSummaryFetchObj.isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }
  let records = trainingSummaryFetchObj.data?.records;
  let employeeData =
    userDetailsFetchObj.data &&
    userDetailsFetchObj.data?.employeeDetails.data &&
    userDetailsFetchObj.data?.employeeDetails.data.employees[0];

  // If user is not supertrainer, only show records for the trainer's region
  let isSuperTrainer = employeeData?.isSuperTrainer;

  if (!isSuperTrainer) {
    // get user location
    const tLocation = employeeData?.ous.find((ou) => ou.type === "Location");
    const tDivision = employeeData?.ous.find((ou) => ou.type === "Division");

    const tLocationId = tLocation?.ouId;
    const tLocationName = tLocation?.name;
    const tDivisionName = tDivision?.name;

    // if vendor, show only records from with  same locationId and division
    if (tLocationId && tLocationId?.toLowerCase().indexOf("vn") > -1) {
      records = records?.filter(
        (r) => r.locationId === tLocationId && r.division === tDivisionName
      );
    } else {
      // else show all records from location
      records = records?.filter((r) => r.location === tLocationName);
    }
  }

  if (location) {
    records = records?.filter((r) => r.location === location);
  }

  // Calculate Summary for each certification
  const groupedByCertification = groupBy(records, (r: RecordsEntity) => {
    return r.certificationTitle;
  });

  var summaryArray: CertSummaryItem[] = [];

  config.certifications.forEach((certification) => {
    if (!groupedByCertification[certification]) return;

    var currRecords = groupedByCertification[certification];
    var g = groupBy(currRecords, (r: RecordsEntity) => {
      return `${r.userId}`;
    });
    var notStarted: UserSummaryItem[] = [];
    var inProgress: UserSummaryItem[] = [];
    var pendingOjt: UserSummaryItem[] = [];
    var completed: UserSummaryItem[] = [];
    forOwn(g, (val: RecordsEntity[], key: string) => {
      // if all training is not started
      if (
        val.every(
          (i) =>
            i.trainingStatus !== SectionItemStatus.Completed &&
            i.trainingStatus !== SectionItemStatus.InProgress
        )
      ) {
        notStarted.push({ id: key, items: val });
      }
      // if all courses are either completed or it is an incomplete OJT
      else if (
        val.some((i) => i.trainingTitle.endsWith(OJTSuffix)) &&
        val.every(
          (i) =>
            i.trainingStatus === SectionItemStatus.Completed ||
            (i.trainingTitle.endsWith(OJTSuffix) &&
              i.trainingStatus !== SectionItemStatus.Completed)
        )
      ) {
        pendingOjt.push({ id: key, items: val });
      }
      // if any course is in progress
      else if (
        val.some((i) => i.trainingStatus === SectionItemStatus.InProgress)
      ) {
        inProgress.push({ id: key, items: val });
      }
      // otherwise all courses completed
      else {
        completed.push({ id: key, items: val });
      }
    });

    summaryArray.push({
      certification,
      notStarted,
      inProgress,
      pendingOjt,
      completed,
    });
  });

  return (
    <Table>
      <div>
        <Italics>
          *
          {`${t("trainer.lastUpdatedLabel")} ${
            trainingSummaryFetchObj.data?.lastUpdated
          } PST`}
        </Italics>
      </div>
      {summaryArray.map((k, i) => {
        var course = config.courses.find(
          (c) => c.certification === k.certification
        );
        return <CertificationSummaryWidget item={k} course={course} key={i} />;
      })}
    </Table>
  );
}

const Table = styled.div`
  display: grid;
  grid-template-columns: auto;
  width: 100%;
`;

const Italics = styled.span`
  font-style: italic;
`;
