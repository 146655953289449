import { Course } from "../../../types/Config";
import { CertificationTransDetailsEntity } from "../../../types/Transcript";
import {
  CertificationStatus,
  SectionItemStatus,
} from "../../../utils/Constants";

function getCourseStatus(
  course: Course,
  courses: Course[],
  t: Function,
  certifications?: CertificationTransDetailsEntity[]
) {
  let isInProgress = true;
  var currentCertifications: CertificationTransDetailsEntity[] | undefined =
    certifications?.filter((c) => c.CertificateTitle === course.certification);

  let subCourses = courses.filter((c) => c.parentCourseId === course.courseId);

  // If subcourses present, then use the certs for the subcourses
  if (subCourses && subCourses.length > 0) {
    let subCerts = subCourses.map((s) => s.certification);
    currentCertifications = certifications?.filter((c) =>
      subCerts.some((s) => s === c.CertificateTitle)
    );
  }

  if (
    currentCertifications &&
    currentCertifications?.every(
      (c) => c.CertificationStatus === CertificationStatus.Certified
    )
  )
    isInProgress = false;

  let assigned: boolean = false;
  if (currentCertifications && currentCertifications?.length > 0) {
    assigned = true;
  }

  let courseStatusText = "";

  if (isInProgress) {
    let notStarted = currentCertifications?.every(
      (c) =>
        c.Sections &&
        c.Sections.every((s) =>
          s.SectionItems.every(
            (si) =>
              si.Status !== SectionItemStatus.InProgress &&
              si.Status !== SectionItemStatus.Completed
          )
        )
    );
    if (notStarted) courseStatusText = t("courseDetails.notStarted");
    else courseStatusText = t("courseDetails.inProgress");
  } else courseStatusText = t("courseDetails.completed");

  if (!assigned) {
    courseStatusText = t("courseDetails.notAssigned");
  }

  return {
    assigned,
    isInProgress,
    courseStatusText,
  };
}

export default getCourseStatus;
