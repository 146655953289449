import React from "react";
import { Section, Course } from "../../../types/Config";
import { CertificationTransDetailsEntity } from "../../../types/Transcript";
import { SectionStyles } from "../../../configuration";
import PlainMapSection from "./PlainMapSection";
import BoxMapSection from "./BoxMapSection";

export interface MapSectionProps {
  section: Section;
  certifications?: CertificationTransDetailsEntity[];
  courses: Course[];
}

export default function CertificationMapSection({
  section,
  certifications,
  courses,
}: MapSectionProps) {
  return section.sectionStyle === SectionStyles.NO_BOX ? (
    <PlainMapSection
      section={section}
      certifications={certifications}
      courses={courses}
    />
  ) : (
    <BoxMapSection
      section={section}
      certifications={certifications}
      courses={courses}
    />
  );
}

/*

*/
