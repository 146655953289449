import React from "react";
import styled from "styled-components";
import { Breakpoint_MD } from "../utils/Constants";

interface ImageSectionProps {
  src: string;
  className?: string;
  title?: string;
}

export default function ImageSection({
  src,
  className,
  title,
}: ImageSectionProps) {
  return (
    <>
      <ImageSectionDiv
        src={src}
        className={`${className} img-section-div`}
        title={title}
      />
      <Image
        src={src}
        className={`${className} img-section-img`}
        title={title}
      />
    </>
  );
}

const ImageSectionDiv = styled.div<{ src: string }>`
  background: url(${(props) => props.src});
  background-size: cover;
  @media screen and (max-width: ${Breakpoint_MD}) {
    display: none;
  }
`;

const Image = styled.img`
  display: none;
  @media screen and (max-width: ${Breakpoint_MD}) {
    display: block;
  }
`;
