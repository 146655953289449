import React from "react";
import { SectionItemsEntity } from "../../../types/Transcript";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import CertificationList from "../components/CertificationList";
import CourseRefs from "../components/CourseRefs";
import blueBg from "../../../resources/Blue.png";
import { CourseStatus } from "../components/CourseStatus";
import useCertificationDetails from "../api/useCertificationDetails";
import { useTranslation } from "react-i18next";
import CurrentLanguageText from "../../../components/CurrentLanguageText";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { Breakpoint_MD } from "../../../utils/Constants";

interface SectionContentProps {
  moveUp?: boolean;
}

export const CourseDetails = () => {
  var certificationDetailsFetchObj = useCertificationDetails();
  const { courseId } = useParams();
  const { t } = useTranslation();

  if (certificationDetailsFetchObj.isLoading) {
    return (
      <BlueSection>
        <LoadingSpinner />
      </BlueSection>
    );
  }

  var courses = certificationDetailsFetchObj.data?.config?.courses;

  if (!courseId) {
    return <div>{t("courseDetails.courseNotFound")}</div>;
  }

  const course = courses?.find((c) => c.courseId === parseInt(courseId));
  if (!course) {
    return <div>{t("courseDetails.courseNotFound")}</div>;
  }

  let certificationList =
    certificationDetailsFetchObj.data?.transcript.data[0]
      .CertificationTransDetails;
  let currentCert = certificationList?.find(
    (c) => c.CertificateTitle === course.certification
  );

  let subCourses = courses?.filter(
    (c) =>
      c.parentCourseId === course.courseId &&
      certificationList?.some((l) => l.CertificateTitle === c.certification)
  );

  let sectionItems: SectionItemsEntity[] = [];
  currentCert?.Sections?.forEach(
    (s) => (sectionItems = [...sectionItems, ...s.SectionItems])
  );

  console.log("c:", course.courseTitle, course.courseModules);

  return (
    <CourseContainer
      gridGap={subCourses && subCourses.length === 0 ? "1.25rem" : ""}
    >
      <PageIntro moveUp={subCourses && subCourses?.length > 0}>
        <ImageSection>
          <Image src={course?.icon?.default} />
        </ImageSection>
        <PageIntroContent>
          <PageIntroHeader>
            {course.certification || course.courseTitle}
          </PageIntroHeader>
          {subCourses && subCourses?.length > 0 && (
            <div className="course-description">
              <CurrentLanguageText text={course.description} />
            </div>
          )}
          {(subCourses === null || subCourses?.length === 0) && (
            <>
              <div>{t("courseDetails.thisCourse")}</div>
            </>
          )}
          <StyledUl>
            {course.courseModules?.map((module) => (
              <li className="course-module">
                <CurrentLanguageText text={module} />
              </li>
            ))}
          </StyledUl>
          {course.courseButton && (
            <StyledButton
              target="_blank"
              rel="noreferrer"
              href={course.courseButton.link}
            >
              <CurrentLanguageText text={course.courseButton.text} />
            </StyledButton>
          )}
          {!course.courseButton && course.cornerstoneUrl && (
            <StyledButton
              target="_blank"
              rel="noreferrer"
              href={course.cornerstoneUrl}
            >
              {t("courseDetails.cornerstoneButtonLabel")}
            </StyledButton>
          )}
        </PageIntroContent>
        {subCourses?.length === 0 ? (
          <CourseStatus certification={currentCert} />
        ) : null}
      </PageIntro>

      <SectionContent moveUp={subCourses === null || subCourses?.length !== 0}>
        {subCourses?.length === 0 ? (
          <CourseContent>
            <CourseRefs
              courseDetails={course}
              showDescription={false}
              certifications={certificationList}
            />
          </CourseContent>
        ) : (
          <CertificationList
            courseList={subCourses}
            certifications={certificationList}
          />
        )}
      </SectionContent>
    </CourseContainer>
  );
};

interface CustomProps {
  gridGap: string;
}
const CourseContainer = styled.div<CustomProps>`
  display: grid;
  grid-template-rows: 300px auto;
  grid-gap: ${(p: CustomProps) => p.gridGap};
  grid-template-areas:
    "intro"
    "content";
  background: #c8c9c7;
  @media screen and (max-width: ${Breakpoint_MD}) {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
`;

const SectionContent = styled.div<SectionContentProps>`
  padding: 0;
  color: #01426a;
  border-bottom: 20px solid #c8c9c7;
  background-color: ${(props) => (props.moveUp ? "" : "#fff")};
  margin-top: ${(props) => (props.moveUp ? "-50px" : "0")};
`;

const BlueSection = styled.div`
  background-image: url(${blueBg});
  background-size: cover;
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-items: center;
`;

const PageIntro = styled(BlueSection)<SectionContentProps>`
  grid-template-columns: 15% 40% 45%;
  padding-bottom: ${(props) => (props.moveUp ? "50px" : "0")};
  @media screen and (max-width: ${Breakpoint_MD}) {
    grid-template-columns: auto;
  }
`;

const PageIntroHeader = styled.h2`
  color: rgba(0, 116, 200, 1);
  text-transform: uppercase;
`;

const PageIntroContent = styled.div`
  padding-bottom: 15px;
  @media screen and (max-width: ${Breakpoint_MD}) {
    padding: 0 10px;
  }
`;

const ImageSection = styled.div`
  text-align: center;
  padding: 20px 0;
`;

const Image = styled.img`
  width: 220px;
  height: 220px;
`;

const StyledButton = styled.a`
  height: 50px;
  width: 200px;
  background: #00c7e6;
  border-radius: 5px;
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  line-height: 50px;
`;

const CourseContent = styled.div`
  width: 55%;
  @media screen and (max-width: ${Breakpoint_MD}) {
    width: 100%;
  }
`;

const StyledUl = styled.ul`
  padding-inline-start: 15px;
`;

export default CourseDetails;
