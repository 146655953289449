import styled from "styled-components";
import * as React from "react";
import { Header } from "./features/Header/header";
import { AppRoutes } from "./routes";
import { AppProvider } from "./providers/app";
import WelcomeBanner from "./features/Header/banner";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

function App() {
  return (
    <AppProvider>
      <QueryClientProvider client={queryClient}>
        <Container>
          <Header />
          <WelcomeBanner />
          <AppRoutes />
        </Container>
      </QueryClientProvider>
    </AppProvider>
  );
}

const Container = styled.div`
  display: grid;
  height: 100vh;
  grid-template-rows: 0.08fr 30px auto;
`;

export default App;
