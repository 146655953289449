import React from "react";
import styled from "styled-components";
import logo from "../resources/alaska_logo.png";
import { Breakpoint_MD } from "../utils/Constants";

export default function Logo() {
  return <StyledLogo src={logo} className="logo" />;
}

const StyledLogo = styled.img`
  width: 20em;
  padding: 0.2em 0.1em;
  cursor: pointer;
  @media screen and (max-width: ${Breakpoint_MD}) {
    margin-right: 50px;
  }
`;
