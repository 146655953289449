import React from "react";
import styled from "styled-components";
import { Section, Course } from "../../../types/Config";
import { CertificationTransDetailsEntity } from "../../../types/Transcript";
import CourseProgressMapSection from "./CourseProgressMapSection";

const CourseDiv = styled.div``;

export default function PlainMapSection({
  section,
  certifications,
  courses,
}: {
  section: Section;
  certifications?: CertificationTransDetailsEntity[];
  courses: Course[];
}) {
  return (
    <CourseDiv className="map-section plain-map">
      {courses
        .filter((c) => c.sectionId === section.sectionId)
        .map((c) => {
          return (
            <CourseProgressMapSection
              key={c.courseId}
              course={c}
              courses={courses}
              certifications={certifications}
              displayStyle={0}
            />
          );
        })}
    </CourseDiv>
  );
}
