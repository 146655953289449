import React from "react";
import styled from "styled-components";
import learnerHome from "../assets/learner-home.png";
import { useTranslation, Trans } from "react-i18next";
import LinkText from "../../../components/LinkText";
import LearnerCertificationMap from "../components/LearnerCertificationMap";
import { Colors } from "../../../utils/ColorHelpers";
import { Breakpoint_MD } from "../../../utils/Constants";
import ImageSection from "../../../components/ImageSection";

export const LearnerHome = () => {
  const { t } = useTranslation();

  return (
    <LearnerContainer>
      <SectionIntro>
        <PageIntro>
          <PageIntroContent>
            <PageIntroHeader className="page-header">
              {t("learnerHome.title")}
            </PageIntroHeader>
            <Trans i18nKey="learnerHome.description.intro"></Trans>
            <ul>
              <li>{t("learnerHome.description.list1")}</li>
              <li>{t("learnerHome.description.list2")}</li>
              <li>{t("learnerHome.description.list3")}</li>
            </ul>
            <Trans
              i18nKey="learnerHome.description.outro"
              components={{
                link1: (
                  <LinkText
                    to={"resources/IRSA-Training-Journey-Map.png"}
                    title="Training map"
                    target="_blank"
                    className="training-map"
                  ></LinkText>
                ),
              }}
            ></Trans>
          </PageIntroContent>
          <ImageSection src={learnerHome} />
        </PageIntro>
      </SectionIntro>
      <LearnerCertificationMap />
    </LearnerContainer>
  );
};

const LearnerContainer = styled.div`
  display: grid;
  grid-template-rows: 0.3fr auto;
  grid-gap: 1.25rem;
  background-color: ${Colors.BgGray};
`;

const SectionIntro = styled.div`
  display: grid;
  background: #ffffff;
`;

const PageIntro = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  @media screen and (max-width: ${Breakpoint_MD}) {
    grid-template-columns: auto;
    grid-template-rows: minmax(200px, auto) auto;
  }
`;

const PageIntroHeader = styled.h2`
  color: ${Colors.Blue};
`;

const PageIntroContent = styled.div`
  padding: 0px 20px;
  color: #01426a;
`;

export default LearnerHome;
